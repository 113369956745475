import { AuthGuard } from './core/guard/auth.guard';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MobilePlatformGuard } from './core/guard/mobile-platform.guard';
import { RedirectGuard } from './core/guard/redirect.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/welcome-app',
    pathMatch: 'full'
  },
  {
    path: 'welcome-app',
    loadChildren: () =>
      import('./pages/welcome/welcome.module').then(
        (m) => m.WelcomePageModule
      ),
    data: {
      title: 'movsa: Bienvenido'
    },
    canActivate: [RedirectGuard, MobilePlatformGuard]
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./pages/loginWeb/login-web.module').then(
        (m) => m.LoginWebPageModule
      ),
    data: {
      title: 'movsa: Login'
    }
  },
  {
    path: 'politica',
    loadChildren: () =>
      import('./pages/policyWeb/policy.module').then(
        (m) => m.PolicyModule
      ),
    data: {
      title: 'movsa: Política'
    }
  },
  {
    path: 'registro',
    loadChildren: () =>
      import(
        './pages/registerWeb/register-web.module'
      ).then((m) => m.RegisterWebPageModule),
    data: {
      title: 'movsa: Registro'
    }
  },
  {
    path: 'perfil',
    loadChildren: () =>
      import('./pages/profileWeb/profile-web.module').then(
        (m) => m.ProfileWebPageModule
      ),
    data: {
      title: 'movsa: Perfil'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'diario',
    loadChildren: () =>
      import('./pages/diaryWeb/diary-web.module').then(
        (m) => m.DiaryWebPageModule
      ),
    data: {
      title: 'movsa: Diario'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'diario/comparar/:residentId/:sessionId/:type',
    loadChildren: () =>
      import(
        './pages/diary-compare-web/diary-compare-web.module'
      ).then((m) => m.DiaryCompareWebPageModule),
    data: {
      title: 'movsa: Diario'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'planif',
    loadChildren: () =>
      import('./pages/planifWeb/planif-web.module').then(
        (m) => m.PlanifWebPageModule
      ),
    data: {
      title: 'movsa: Protocolo'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'planif/comparar/:residentId/:sessionId/:type',
    loadChildren: () =>
      import(
        './pages/planif-compare-web/planif-compare-web.module'
      ).then((m) => m.PlanifCompareWebPageModule),
    data: {
      title: 'movsa: Protocolo'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'adequipos',
    loadChildren: () =>
      import(
        './pages/adminequipos-web/adminequipos-web.module'
      ).then((m) => m.AdminequiposWebPageModule),
    data: {
      title: 'movsa: Admin Equipos'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'asequipos/:id',
    loadChildren: () =>
      import(
        './pages/asignaequipos-web/asignaequipos-web.module'
      ).then((m) => m.AsignaequiposWebPageModule),
    data: {
      title: 'movsa: Asig Equipos'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'directo',
    loadChildren: () =>
      import('./pages/directWeb/direct-web.module').then(
        (m) => m.DirectWebPageModule
      ),
    data: {
      title: 'movsa: Directo'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'avisos',
    loadChildren: () =>
      import('./pages/noticeWeb/notice-web.module').then(
        (m) => m.NoticeWebModule
      ),
    data: {
      title: 'movsa: Avisos'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'invitaciones',
    loadChildren: () =>
      import(
        './pages/invitationWeb/invitation-web.module'
      ).then((m) => m.InvitationWebPageModule),
    data: {
      title: 'movsa: Invitaciones'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'dispositivos',
    loadChildren: () =>
      import('./pages/deviceWeb/device-web.module').then(
        (m) => m.DeviceWebPageModule
      ),
    data: {
      title: 'movsa: Dispositivos'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'monitores',
    loadChildren: () =>
      import(
        './pages/bleDeviceWeb/bleDevice-web.module'
      ).then((m) => m.BleDeviceWebPageModule),
    data: {
      title: 'movsa: Monitores'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'centros',
    loadChildren: () =>
      import('./pages/centerWeb/center-web.module').then(
        (m) => m.CenterWebPageModule
      ),
    data: {
      title: 'movsa: Centros movsa'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'usuarios',
    loadChildren: () =>
      import('./pages/userWeb/user-web.module').then(
        (m) => m.UserWebPageModule
      ),
    data: {
      title: 'movsa: Usuarios movsa'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'sesiones',
    loadChildren: () =>
      import('./pages/sessionWeb/session-web.module').then(
        (m) => m.SessionWebPageModule
      ),
    data: {
      title: 'movsa: Sesiones movsa'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'login-app',
    loadChildren: () =>
      import('./pages/login/login.module').then(
        (m) => m.LoginPageModule
      ),
    data: {
      title: 'movsa: Login'
    },
    canActivate: [MobilePlatformGuard]
  },
  {
    path: 'registro-app',
    loadChildren: () =>
      import('./pages/register/register.module').then(
        (m) => m.RegisterPageModule
      ),
    data: {
      title: 'movsa: Registro'
    },
    canActivate: [MobilePlatformGuard]
  },
  {
    path: 'notificaciones-app',
    loadChildren: () =>
      import(
        './pages/notifications/notifications.module'
      ).then((m) => m.NotificationsPageModule),
    data: {
      title: 'movsa: Notificaciones'
    },
    canActivate: [MobilePlatformGuard, AuthGuard]
  },
  {
    path: 'diario-app',
    loadChildren: () =>
      import('./pages/diary/diary.module').then(
        (m) => m.DiaryPageModule
      ),
    data: {
      title: 'movsa: Diario'
    },
    canActivate: [MobilePlatformGuard, AuthGuard],
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'perfil-app',
    loadChildren: () =>
      import('./pages/profile/profile.module').then(
        (m) => m.ProfilePageModule
      ),
    data: {
      title: 'movsa: Perfil'
    },
    canActivate: [MobilePlatformGuard]
  },
  {
    path: 'terminos-app',
    loadChildren: () =>
      import('./pages/terms/terms.module').then(
        (m) => m.TermsPageModule
      ),
    data: {
      title: 'movsa: Terminos y condiciones'
    },
    canActivate: [MobilePlatformGuard]
  },
  {
    path: 'privacidad-app',
    loadChildren: () =>
      import('./pages/privacy/privacy.module').then(
        (m) => m.PrivacyPageModule
      ),
    data: {
      title: 'movsa: Politica de privacidad'
    },
    canActivate: [MobilePlatformGuard]
  },
  {
    path: 'avisos-app',
    loadChildren: () =>
      import('./pages/alerts/alerts.module').then(
        (m) => m.AlertsPageModule
      ),
    data: {
      title: 'movsa: Avisos'
    },
    canActivate: [MobilePlatformGuard, AuthGuard]
  },
  {
    path: 'directo-app',
    loadChildren: () =>
      import('./pages/live/live.module').then(
        (m) => m.LivePageModule
      ),
    data: {
      title: 'movsa: Directo'
    },
    canActivate: [MobilePlatformGuard, AuthGuard]
  },
  {
    path: 'bleDevices-app',
    loadChildren: () =>
      import('./pages/bleDevices/bleDevices.module').then(
        (m) => m.BleDevicesPageModule
      ),
    data: {
      title: 'movsa: Blee Dispositivos'
    },
    canActivate: [MobilePlatformGuard, AuthGuard]
  },
  {
    path: 'configuracion-app',
    loadChildren: () =>
      import('./pages/settings/settings.module').then(
        (m) => m.SettingsPageModule
      ),
    data: {
      title: 'movsa: Configuracion'
    },
    canActivate: [MobilePlatformGuard, AuthGuard]
  },
  {
    path: 'contacto-app',
    loadChildren: () =>
      import('./pages/contact/contact.module').then(
        (m) => m.ContactPageModule
      ),
    data: {
      title: 'movsa: Contacto'
    },
    canActivate: [MobilePlatformGuard, AuthGuard]
  },
  {
    path: 'perfil-monitorizado-app',
    loadChildren: () =>
      import(
        './pages/resident-profile/resident-profile.module'
      ).then((m) => m.ResidentProfilePageModule),
    data: {
      title: 'movsa: Perfil Monitorizado'
    },
    canActivate: [MobilePlatformGuard, AuthGuard]
  },
  {
    path: 'eventos-app',
    loadChildren: () =>
      import('./pages/events/events.module').then(
        (m) => m.EventsPageModule
      ),
    data: {
      title: 'movsa: Eventos'
    },
    canActivate: [MobilePlatformGuard, AuthGuard]
  },
  {
    path: 'refresh',
    loadChildren: () =>
      import('./pages/refresh/refresh.module').then(
        (m) => m.RefreshPageModule
      )
  },
  {
    path: 'diary-compare-web',
    loadChildren: () =>
      import(
        './pages/diary-compare-web/diary-compare-web.module'
      ).then((m) => m.DiaryCompareWebPageModule)
  },
  {
    path: 'menu-app',
    loadChildren: () =>
      import('./pages/menu-work/menu-work.module').then(
        (m) => m.MenuWorkModule
      ),
    data: {
      title: 'Movsa: Menu tareas'
    },
    canActivate: [MobilePlatformGuard, AuthGuard]
    //canActivate: [AuthGuard]
  },
  {
    path: 'registro-tareas',
    loadChildren: () =>
      import('./pages/records/records.module').then(
        (m) => m.RecordsModule
      ),
    data: {
      title: 'movsa: Registros'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'menu-app-web',
    loadChildren: () =>
      import('./pages/menu-workWeb/menu-work.module').then(
        (m) => m.MenuWorkModule
      ),
    data: {
      title: 'Movsa: Menu tareas'
    },
    //canActivate: [MobilePlatformGuard, AuthGuard],
    canActivate: [AuthGuard]
  },
  {
    path: 'registro-tareas-web',
    loadChildren: () =>
      import('./pages/recordsWeb/records.module').then(
        (m) => m.RecordsModule
      ),
    data: {
      title: 'movsa: Registros'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'registro-incidencias',
    loadChildren: () =>
      import(
        './pages/incidences-admin-web/incidences-admin-web.module'
      ).then((m) => m.IncidencesAdminWebModule),
    data: {
      title: 'movsa: Registros Incidencias'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'registro-habitaciones',
    loadChildren: () =>
      import(
        './pages/room-admin-web/room-admin-web.module'
      ).then((m) => m.RoomAdminWebModule),
    data: {
      title: 'movsa: Registros Habitaciones'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'generador-codigo-qr-web',
    loadChildren: () =>
      import(
        './pages/code-generator-admin-web/code-generator-admin-web.module'
      ).then((m) => m.CodeGeneratorAdminWebModule),
    data: {
      title: 'movsa: Generador Codigo QR'
    },
    canActivate: [AuthGuard]
  } /*,
  {
    path: 'adminequipos-web',
    loadChildren: () => import('./pages/adminequipos-web/adminequipos-web.module').then( m => m.AdminequiposWebPageModule)
  },
  {
    path: 'asignaequipos-web',
    loadChildren: () => import('./pages/asignaequipos-web/asignaequipos-web.module').then( m => m.AsignaequiposWebPageModule)
  }*/
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload',
      relativeLinkResolution: 'legacy'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
